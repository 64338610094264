<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
      <el-select v-model="selectedPart" clearable placeholder="请选择部门">
        <el-option v-for="part in partments" :key="part.id" :label="part.name" :value="part.id"></el-option>
      </el-select>
      <el-select v-model="selectedLine" clearable placeholder="请选择车间">
        <el-option v-for="line in lines" :key="line.id" :label="line.name" :value="line.id"></el-option>
      </el-select>
      <el-checkbox v-model="important" style="margin-left: 10px">关键设备</el-checkbox>
      <el-checkbox v-model="rcaFlag" >有RCA分析的设备</el-checkbox>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
<!--      <el-upload action="http://localhost:9090/equipment/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">-->
      <el-upload action="http://39.106.92.106:9090/equipment/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="60" sortable></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="type" label="种类"></el-table-column>
      <el-table-column label="所属部门">
        <template v-slot="scope">
          <span>{{ getPartmentName(scope.row.partId)  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属车间">
        <template v-slot="scope">
          <span>{{ getLineName(scope.row.lineId)  }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column prop="type" label="类型"></el-table-column>-->
      <el-table-column prop="mtbf" label="MTBF(平均故障间隔时间)" width="170"></el-table-column>
      <el-table-column prop="mttr" label="MTTR(平均故障修复时间)" width="170"></el-table-column>
      <el-table-column prop="mttf" label="MTTF(平均失效前时间)" width="160"></el-table-column>
      <el-table-column label="SOP文件">
        <template slot-scope="scope">
          <span>{{ getFileName(scope.row.avatarUrl) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关键设备">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.important" active-color="#13ce66" inactive-color="#ccc"
                     @change="changeEnable(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-row type="flex" justify="center" style="margin-top: 10px;">
            <el-col class="text-center">
              <el-button type="primary" plain @click="viewChart(scope.row)" size="small">设备历史</el-button>
              <el-button type="primary" plain @click="viewFile(scope.row)" size="small">RCA分析</el-button>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center" style="margin-top: 10px;">
            <el-col class="text-center">
              <el-button type="success" @click="handleEdit(scope.row)" size="small">编辑 <i class="el-icon-edit"></i></el-button>
              <el-popconfirm
                  class="ml-5"
                  confirm-button-text='确定'
                  cancel-button-text='我再想想'
                  icon="el-icon-info"
                  icon-color="red"
                  title="您确定删除吗？"
                  @confirm="del(scope.row.id)"
              >
                <el-button type="danger" slot="reference" size="small">删除 <i class="el-icon-remove-outline"></i></el-button>
              </el-popconfirm>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-select clearable v-model="form.partId" placeholder="请选择部门" style="width: 100%">
            <el-option v-for="item in partments" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车间">
          <el-select clearable v-model="form.lineId" placeholder="请选择车间" style="width: 100%">
            <el-option v-for="item in filteredLines" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="种类">
          <el-input v-model="form.type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="MTBF(平均故障间隔时间)">
          <el-input v-model="form.meanWork" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="MTTR(平均故障修复时间)">
          <el-input v-model="form.meanRepair" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="MTTF(平均失效前时间)">
          <el-input v-model="form.meanFailure" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="SOP">
        <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"
                   :on-success="handleAvatarSuccess" style="display: inline-block" :multiple="false">
          <el-button type="flex" justify="center">上传SOP</el-button>
        </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="故障历史" :visible.sync="dialogFormVisible2" width="80%" :close-on-click-modal="false">
      <div style="margin: 10px 0">
        <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
        <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
        <el-button type="primary" plain @click="showChart(reports.id)" size="small">
          查看报表
        </el-button>
      </div>
      <el-table :data="reports" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
        <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
        <el-table-column label="报修人">
          <template v-slot="scope">
            <span>{{ getUserName(scope.row.userId)  }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备">
          <template v-slot="scope">
            <span>{{ equipmentName  }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="failureType" label="故障类型"></el-table-column>
        <el-table-column prop="state" label="状态"></el-table-column>
        <el-table-column prop="recordTime" label="报修时间"></el-table-column>
        <el-table-column prop="failureTime" label="故障时间"></el-table-column>
        <el-table-column prop="description" label="故障描述"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="success" @click="gotoDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 10px 0">
        <el-pagination
            @size-change="handleSizeChange1(scope.row)"
            @current-change="handleCurrentChange1(scope.row)"
            :current-page="pageNum1"
            :page-sizes="[2, 5, 10, 20]"
            :page-size="pageSize1"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total1">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog title="文件清单" :visible.sync="dialogFormVisible3" width="30%" :close-on-click-modal="false">
      <div style="margin-bottom: 20px;">
        <div style="margin-bottom: 20px;"><span>设备ID：{{ form.equipmentId }}</span></div>
        <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"
                     :on-success="handleAvatarSuccess" style="display: inline-block">
          <el-button type="flex" justify="center">上传文件</el-button>
        </el-upload>
        <el-button type="primary" @click="save2" style="margin-left: 20px;">确 定</el-button>
      </div>
      <el-table :data="files" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
        <el-table-column prop="id" label="ID" width="80" sortable></el-table-column>
        <el-table-column prop="equipmentId" label="设备ID" width="80"></el-table-column>
        <el-table-column label="文件名">
          <template slot-scope="scope">
            <span>{{ getFileName(scope.row.avatarUrl) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template slot-scope="scope">
            <el-button type="primary" @click="download(scope.row.avatarUrl)">下载</el-button>
            <el-popconfirm
                class="ml-5"
                confirm-button-text='确定'
                cancel-button-text='我再想想'
                icon="el-icon-info"
                icon-color="red"
                title="您确定删除吗？"
                @confirm="del2(scope.row)"
            >
              <el-button type="danger" slot="reference" style="margin-left: 10px;">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="详情" :visible.sync="dialogFormVisible4" width="60%" :close-on-click-modal="false">
      <div class="dialog-content">
        <!-- 左边部分 -->
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>报修单</span>
          </div>
          <el-form label-width="100px" size="large">
            <el-form-item label="报修人:">
              <span>{{getUserName(user.id)}}</span>
            </el-form-item>
            <el-form-item label="设备:">
              <span>{{ equipmentName }}</span>
            </el-form-item>
            <el-form-item label="工单类型:">
              <span>{{ form.type }}</span>
            </el-form-item>
            <el-form-item label="故障类型:">
              <span>{{ form.failureType }}</span>
            </el-form-item>
            <el-form-item label="报修时间:">
              <span>{{ form.recordTime }}</span>
            </el-form-item>
            <el-form-item label="故障时间:">
              <span>{{ form.failureTime }}</span>
            </el-form-item>
            <el-form-item label="故障描述:">
              <span>{{ form.description }}</span>
            </el-form-item>
            <el-form-item label="故障图:">
              <img v-if="form.avatarUrl" :src="form.avatarUrl" class="avatarUrl">
            </el-form-item>
          </el-form>
        </el-card>
        <!-- 右边部分 -->
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>工单</span>
          </div>
          <el-form label-width="100px" size="large">
            <el-form-item label="ID">
              <span>{{ records.id }}</span>
            </el-form-item>
            <el-form-item label="工单号">
              <span>{{ records.reconum }}</span>
            </el-form-item>
            <el-form-item label="类型">
              <span>{{ records.type }}</span>
            </el-form-item>
            <el-form-item label="状态">
              <span>{{ records.state }}</span>
            </el-form-item>
            <el-form-item label="开始时间">
              <span>{{ records.startTime }}</span>
            </el-form-item>
            <el-form-item label="暂停时间">
              <span>{{ records.stopTime }}</span>
            </el-form-item>
            <el-form-item label="结束时间">
              <span>{{ records.endTime }}</span>
            </el-form-item>
            <el-form-item label="工时(h)">
              <span>{{ records.workHour }}</span>
            </el-form-item>
            <el-form-item label="维修分析">
              <span>{{ records.analysis }}</span>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </el-dialog>

    <el-dialog title="工时报表" :visible.sync="dialogFormVisible5" width="50%" >
      <el-card style="width: 100%">
        <div ref="lineChart" class="chart"></div>
      </el-card>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import * as echarts from "echarts";


export default {
  name: "Equipment",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      total1: 0,
      pageNum1: 1,
      pageSize1: 10,
      name: "",
      equipmentName: "",
      form: {},
      selectedPart: null,
      selectedLine: null,
      important: false,
      rcaFlag: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      dialogFormVisible4: false,
      dialogFormVisible5: false,
      multipleSelection: [],
      partments: [],
      lines: [],
      files: [],
      reports: [],
      records: [],
      recomaintain: [],
      equipmentFiles: [],
      users: [],
      selectedEquipmentId: null,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      baseURLdata: request.defaults.baseURL,
      lineChart: null,
    }
  },
  created() {
    this.loadState()
    this.load()
  },
  beforeDestroy() {
    // 在组件销毁时销毁图表实例
    if (this.lineChart) {
      this.lineChart.dispose();
    }
  },
  computed: {
    filteredLines() {
      // 根据选择的部门过滤车间
      return this.lines.filter(line => line.partId === this.form.partId);
    },
  },
  methods: {
    load() {
      this.request.get("/equipment/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
          partId: this.selectedPart,
          lineId: this.selectedLine,
          important: this.important,
          rcaFlag: this.rcaFlag
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/partment").then(res => {
        this.partments = res.data
      })
      this.request.get("/line").then(res => {
        this.lines = res.data
      })
      this.saveState()
    },
    saveState() {
      const state = {
        name: this.name,
        partId: this.selectedPart,
        lineId: this.selectedLine,
        important: this.important,
        rcaFlag: this.rcaFlag,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      sessionStorage.setItem('EquipmentState', JSON.stringify(state));
    },
    loadState() {
      const savedState = sessionStorage.getItem('EquipmentState');
      if (savedState) {
        const { name, partId, lineId, important, rcaFlag, pageNum, pageSize } = JSON.parse(savedState);
        this.name = name || '';
        this.partId = partId || null;
        this.lineId = lineId || null;
        this.important = important || false;
        this.rcaFlag = rcaFlag || false;
        this.pageNum = pageNum || 1;
        this.pageSize = pageSize || 10;
      }
    },
    save() {
      this.request.post("/equipment", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    save2() {
      this.request.post("/equipmentFile", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.loadFile(this.form.equipmentId)
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    showChart(){
      this.renderLineChart(this.selectedEquipmentId);
      this.dialogFormVisible5 = true
    },
    // 渲染折线图
    renderLineChart(equipmentId) {
      this.request.get('/equipmentChart/findById/' + equipmentId)
          .then(response => {
            console.log('Response:', response);
            const data = response.data;

            if (data && (Array.isArray(data) || typeof data === 'object')) {
              const records = Array.isArray(data) ? data : [data];
              const dates = records.map(item => item.updateTime);
              const mttf = records.map(item => item.mttf);
              const mtbf = records.map(item => item.mtbf);
              const mttr = records.map(item => (item.mttr >= 0 ? item.mttr : 0));

              this.$nextTick(() => {
                // 如果存在实例，则销毁
                if (this.lineChart) {
                  this.lineChart.dispose();
                }
                // 创建新实例
                this.lineChart = echarts.init(this.$refs.lineChart);
                const option = {
                  title: { text: '设备曲线' },
                  tooltip: { trigger: 'axis' },
                  legend: { data: ['MTTF', 'MTBF', 'MTTR'] },
                  xAxis: { type: 'category', data: dates },
                  yAxis: { type: 'value', axisLabel: { formatter: (value) => Math.floor(value) } },
                  series: [
                    {
                      name: 'MTTF',
                      type: 'line',
                      data: mttf,
                      label: { show: true, position: 'top', formatter: '{c}' },
                      itemStyle: { color: '#FF5733' },
                      smooth: true
                    },
                    {
                      name: 'MTBF',
                      type: 'line',
                      data: mtbf,
                      label: { show: true, position: 'top', formatter: '{c}' },
                      itemStyle: { color: '#33FF57' },
                      smooth: true
                    },
                    {
                      name: 'MTTR',
                      type: 'line',
                      data: mttr,
                      label: { show: true, position: 'top', formatter: '{c}' },
                      itemStyle: { color: '#3357FF' },
                      smooth: true
                    }
                  ]
                };
                this.lineChart.setOption(option);
              });
            } else {
              console.error('Unexpected data format:', data);
            }
          })
          .catch(error => {
            console.error('Failed to fetch data:', error);
          });
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
    del(id) {
      this.request.delete("/equipment/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    del2(row) {
      this.request.delete("/equipmentFile/" + row.id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.loadFile(row.equipmentId)
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    getUserName(userId) {
      const user = this.users.find(v => v.id === userId);
      if (!user) return '';
      return user ? user.nickname : '';
    },
    getPartmentName(partId) {
      const partment = this.partments.find(v => v.id === partId);
      if (!partment) return '';
      return partment ? partment.name : '';
    },
    getLineName(lineId) {
      const line = this.lines.find(v => v.id === lineId);
      if (!line) return '';
      return line ? line.name : '';
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    handleAvatarSuccess(res) {
      console.log("success"+res)
      this.form.avatarUrl = res.data
    },
    viewChart(row){
      // this.request.get("/repairReport/findByEquipment/"+row.id).then(res => {
      //   this.reports = res.data
      // })
      this.request.get("/repairReport/findByEquipment", {
        params: {
          pageNum: this.pageNum1,
          pageSize: this.pageSize1,
          id: row.id,
        }
      }).then(res => {
        this.reports = res.data.records
        this.total1 = res.data.total
      })
      this.request.get("/recordMaintainer").then(res => {
        this.recomaintain = res.data;
      });
      this.request.get("/user").then(res => {
        this.users = res.data;
      });
      this.equipmentName = row.name
      this.selectedEquipmentId = row.id
      this.dialogFormVisible2 = true
    },
    getMaintainerNames(reconum) {
      const recomt = this.recomaintain.filter(item => item.reconum === reconum);
      return recomt.map(item => {
        const user = this.users.find(u => u.id === item.userId);

        // Check if workHour is a valid number and not null or undefined
        const workHour = item.workHour != null ? item.workHour.toFixed(2) : '0.00';
        const minutes = Math.floor(workHour * 60);

        const allData = user ? `${user.nickname} (${minutes}分钟)` : '';
        return allData;
      }).join(', ');
    },
    changeEnable(row) {
      this.request.post("/equipment", row).then(res => {
        if (res.code === '200') {
          this.$message.success("操作成功")
        }else {
          this.$message.error("操作失败")
        }
      })
    },
    gotoDetail(report){
      this.request.get("/failureRecord/findByReportId/"+report.id).then(res => {
        this.records = res.data;
      });
      this.form=report
      this.dialogFormVisible4 = true
    },
    getFileName(url) {
      // 提取文件名，并移除前面的时间戳部分
      if (url) {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        return fileName.replace(/^\d+-/, ''); // 去掉时间戳部分
      }
      return '';
    },
    loadFile(equipmentId){
      this.request.get("/equipmentFile/findByEquipment/"+equipmentId).then(res => {
        this.files = res.data
      })
    },
    viewFile(row){
      this.loadFile(row.id)
      this.form={
        equipmentId:row.id,
      }
      this.dialogFormVisible3 = true
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/equipment/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.selectedPart = null
      this.selectedLine = null
      this.important = false
      this.rcaFlag = false
      this.load()
      sessionStorage.removeItem('EquipmentState');
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleSizeChange1(row,pageSize) {
      console.log(pageSize1)
      this.pageSize1 = pageSize
      this.viewChart(row)
    },
    handleCurrentChange1(row,pageNum) {
      console.log(pageNum1)
      this.pageNum1 = pageNum
      this.viewChart(row)
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open(this.baseURLdata+"/equipment/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    },
  }
}
</script>


<style>

.dialog-content {
  display: flex;
  justify-content: space-between;
}

.box-card {
  width: 48%; /* Adjust as needed */
  margin: 10px; /* Optional: add margin for better spacing */
}

.avatarUrl {
  width: 100px; /* Adjust as needed */
  height: auto;
}

.chart {
  width: 600px;
  height: 400px;  /* 或者根据需要设置 */
}

</style>
